import React, { useState } from "react";
import {
  useCreateTestMutation,
  useGetTestsQuery,
} from "../../services/testService";

import { TestList } from "../TestList/TestList";
import TestLists from "../TaskLists/TestLists";
import { Card, Modal } from "antd";
import TestForm from "../form/testForm";
import TestListPageUser from "./TestListPageUser";

const TestsListPage = () => {
  const [createTest] = useCreateTestMutation();
  const { data, error, isLoading } = useGetTestsQuery();

  const handleSubmit = async (content) => {
    createTest(content)
      .unwrap()
      .then((content) => console.log(content))
      .catch((err) => console.log(err));
  };
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible((prevState) => !prevState);
  };

  const tabListNoTitle = [
    {
      key: "my_tests",
      tab: "Мои тесты",
    },
    {
      key: "prod_tests",
      tab: "Пройденные тесты",
    },
  ];

  const [activeTabKey2, setActiveTabKey2] = useState("my_tests");

  const onTab2Change = (key: string) => {
    setActiveTabKey2(key);
  };
  return (
    <div>
      {isLoading ? (
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <Card
            loading={isLoading}
            style={{ height: "100%", width: "100%" }}
          ></Card>
          <Card
            loading={isLoading}
            style={{ height: "100%", width: "100%" }}
          ></Card>
        </div>
      ) : (
        <>
          <Card
            style={{ width: "100%" }}
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey2}
            tabBarExtraContent={<a href="#">More</a>}
            onTabChange={(key) => {
              onTab2Change(key);
            }}
          >
            {activeTabKey2 === "my_tests" ? (
              <>
                <Modal
                  visible={visible}
                  handleVisible={handleVisible}
                  onCancel={handleVisible}
                  okButtonProps={{ hidden: true }}
                  cancelButtonProps={{ hidden: true }}
                >
                  <TestForm
                    handleSubmit={handleSubmit}
                    handleVisibleModal={handleVisible}
                  />
                </Modal>
                <TestLists
                  handleSubmit={handleSubmit}
                  handleOpen={handleVisible}
                >
                  <TestList data={data} />
                </TestLists>
              </>
            ) : (
              <TestListPageUser />
            )}
          </Card>
        </>
      )}
    </div>
  );
};

export default TestsListPage;
