import React from "react";

import "./TestLists.css";
import TestForm from "../form/testForm";

import { Button, Card } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";

const TaskLists = ({ children, handleSubmit, handleOpen }) => {
  return (
    <div
      className="taskLists"
      style={{
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
      }}
    >
      <div className="taskLists__Content">
        <Card
          title="Список Тестов"
          style={{ textAlign: "center", width: "100%" }}
          extra={<Button icon={<AppstoreAddOutlined onClick={handleOpen} />} />}
        >
          {children}
        </Card>
      </div>
    </div>
  );
};

export default TaskLists;
